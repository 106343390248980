<template>
  <div class="tools-tab">
    <Timeframes
      v-model="timeframe"
      class="timeframes"
    />

    <ui-button
      v-if="!levelsStore.selectedId"
      :type="2"
      @click="createLevel"
    >
      Create new level
    </ui-button>

    <div
      v-if="levelsStore.selectedId"
      class="tools-tab__direction"
    >
      <ui-button
        :type="4"
        @click="removeLevel"
      >
        Remove
      </ui-button>

      <ui-button
        :type="levelsStore.selectedLevel.direction === 1 ? 1 : 2"
        @click="changeLevelDirection(1)"
      >
        Buy
      </ui-button>

      <ui-button
        :type="levelsStore.selectedLevel.direction === -1 ? 1 : 2"
        @click="changeLevelDirection(-1)"
      >
        Sell
      </ui-button>
    </div>
  </div>
</template>

<script>

import UiButton from '../ui/UiButton'
import Timeframes from '../components/Timeframes'
import { useLevelsStore } from '../store/levels'
import { useChartStore } from '../store/chart'

const chartStore = useChartStore()
const levelsStore = useLevelsStore()

export default {
  components: {
    UiButton,
    Timeframes,
  },

  data() {
    return {
      chartStore,
      levelsStore,
    }
  },

  computed: {
    timeframe: {
      get() {
        return Number(this.chartStore.brokerData.timeframe)
      },
      set(val) {
        this.chartStore.setBrokerData({
          timeframe: val,
        })
      },
    },
  },

  methods: {
    createLevel() {
      const priceCenter = chartStore.getCandlesBounds.maxPrice - chartStore.getCandlesBounds.minPrice
      const dateCenter = chartStore.brokerData.endDate - chartStore.brokerData.startDate

      levelsStore.createNewLevel({
        startDate: chartStore.brokerData.startDate + dateCenter * 0.25,
        endDate: chartStore.brokerData.endDate - dateCenter * 0.25,
        minPrice: chartStore.getCandlesBounds.minPrice + priceCenter * 0.45,
        maxPrice: chartStore.getCandlesBounds.maxPrice - priceCenter * 0.45,
      })
    },

    removeLevel() {
      levelsStore.remove(levelsStore.selectedId)
    },

    changeLevelDirection(direction) {
      levelsStore.setDirection(levelsStore.selectedId, direction)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

.tools-tab
  display flex
  flex-wrap wrap
  flex-direction column
  gap 12px

  &__direction
    display flex
    gap 12px

    > *
      flex-grow 1

.timeframes
  margin-bottom 12px

.range
  margin-bottom 12px
  stylizeRange(rgb(6, 108, 217), var(--c-accent))

</style>
